const links = [
  '<link rel="stylesheet" href="//cdn.jsdelivr.net/npm/tinymce@5.5.1/skins/ui/oxide/content.min.css" integrity="sha256-mikX8rUkWD/9LKKtUPbrJN16DW0uKE/Zq8LBNSBX+Sc=" crossorigin="anonymous">',
  '<link rel="stylesheet" href="//cdn.jsdelivr.net/npm/tinymce@5.5.1/skins/content/default/content.min.css" integrity="sha256-dF/OclalwywIFk5FLZtVZd1Pcyp4kGDNKa6uKguziZc=" crossorigin="anonymous">',
  '<link rel="stylesheet" href="//cdn.jsdelivr.net/npm/prismjs@1.22.0/themes/prism-solarizedlight.css" integrity="sha256-278ChCYdhj8w7EmLSI3+/Z0GIaUwPzWEPIntaHH086I=" crossorigin="anonymous">',
];
const mobileLinks = [
  '<link rel="stylesheet" href="//cdn.jsdelivr.net/npm/tinymce@5.5.1/skins/ui/oxide/content.mobile.min.css" integrity="sha256-VMuwDeMvAF+yH68WynqXPuZxtuCpHiI7uv3kPLglkbQ=" crossorigin="anonymous">',
];
const scripts = [
  '<script src="//cdn.jsdelivr.net/npm/prismjs@1.22.0/components/prism-core.min.js" integrity="sha256-BP8GoJLyZ0s3giW+h7G60XqiH7rZYtLFgGb3MFypAVQ=" crossorigin="anonymous"></script>',
  '<script src="//cdn.jsdelivr.net/npm/prismjs@1.22.0/plugins/autoloader/prism-autoloader.min.js" integrity="sha256-401tqrrIaU+zuwFaNfvIvpFdiEr704VUO2jEEcprPuU=" crossorigin="anonymous"></script>',
];

const part1 = '<!DOCTYPE html><html lang="zh"><head>';
const part2 =
  '<title></title><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover" />';
const part3 = '</head><body>';
const part4 = '</body></html>';

const style = links.join('');
const mobileStyle = mobileLinks.join('');
const script = scripts.join('');

export const buildContentDoc = (content, mobile = false) => {
  const { origin, pathname } = window.location;
  return (
    part1 +
    `<base href="${origin}${pathname}" />` +
    part2 +
    style +
    (mobile ? mobileStyle : '') +
    part3 +
    content +
    script +
    part4
  );
};
