import { setState } from '@/utils/store';
import { request } from '@/core/plugins/api';

export default {
  state: {
    accessToken: '',
    refreshToken: '',
  },
  getters: {
    hasAccessToken: state => !!state.accessToken,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
  },
  mutations: {
    setState,
  },
  actions: {
    saveToken({ commit }, { accessToken, refreshToken }) {
      commit('setState', {
        path: 'accessToken',
        value: accessToken,
      });
      commit('setState', {
        path: 'refreshToken',
        value: refreshToken,
      });
      window.localStorage.setItem('accessToken', accessToken);
      window.localStorage.setItem('refreshToken', refreshToken);
    },
    loadToken({ commit }) {
      const accessToken = window.localStorage.getItem('accessToken');
      const refreshToken = window.localStorage.getItem('refreshToken');
      commit('setState', {
        path: 'accessToken',
        value: accessToken,
      });
      commit('setState', {
        path: 'refreshToken',
        value: refreshToken,
      });
    },
    clearToken({ commit }) {
      commit('setState', {
        path: 'accessToken',
        value: null,
      });
      commit('setState', {
        path: 'refreshToken',
        value: null,
      });
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('refreshToken');
    },
    async login(_, state) {
      // 跳转登录
      const { data } = await request('auth/fetchOauthUrl', {
        state,
      });
      window.location = data;
    },
  },
};
