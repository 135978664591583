<template>
  <div class="buy-detail page">
    <h1 class="page-title">购买详情</h1>
    <div class="card-container">
      <van-cell-group class="card" :border="false">
        <van-cell center title="套餐名称" :value="goodsInfo.name" />
        <van-cell
          center
          title="套餐价格"
          value-class="price"
          :value="`￥${(goodsInfo.price || 0).toFixed(2)}`"
        />
        <van-cell center title="购买账号" :value="accountInfo.account" />
        <van-cell center title="学生姓名" :value="accountInfo.name" />
        <van-cell center title="所在学校" :value="accountInfo.schoolName" />
        <van-cell center title="所在年级" :value="accountInfo.gradeName" />
        <van-cell center title="所在班级" :value="accountInfo.className" />
        <van-cell v-if="isHeadsetCombo" center title="购买数量">
          <van-stepper
            v-model="goodsQuantity"
            theme="round"
            button-size="22"
            disable-input
          />
        </van-cell>
        <van-cell
          v-if="isHeadsetCombo"
          center
          is-link
          icon="location-o"
          title="收货地址"
          :label="addressAreaInfo"
          :value="addresseeInfo"
          @click="addressSelectorVisible = true"
        />
      </van-cell-group>
    </div>

    <van-popup
      v-if="isHeadsetCombo"
      v-model="addressSelectorVisible"
      safe-area-inset-bottom
      round
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="selector-container page-bg">
        <van-address-list
          v-model="addressId"
          :list="addresses"
          @add="handleAddAddress"
          @edit="handleAddressEdit"
          @select="handleAddressSelect"
        >
          <van-empty v-if="!addresses.length" description="暂无地址！" />
        </van-address-list>
      </div>
    </van-popup>

    <van-popup
      v-if="isHeadsetCombo"
      v-model="addressUpdateVisible"
      safe-area-inset-bottom
      round
      position="bottom"
      :style="{ height: '60%' }"
      @closed="addressData = undefined"
    >
      <div class="selector-container page-bg" style="height: calc(100% - 8px)">
        <van-address-edit
          show-postal
          :show-delete="addressData && !!addressData.id"
          :address-info="addressData"
          :area-list="areaData"
          :area-columns-placeholder="['请选择', '请选择', '请选择']"
          :is-saving="saveLoading"
          :is-deleting="deleteLoading"
          @save="handleAddressSave"
          @delete="handleAddressDelete"
        />
      </div>
    </van-popup>

    <van-submit-bar
      :price="price"
      :loading="loading"
      :disabled="submitBarDisabled"
      safe-area-inset-bottom
      button-text="立即购买"
      button-color="#2387f5"
      @submit="displayDisclaimer"
    />

    <van-dialog
      v-model="showDisclaimer"
      show-cancel-button
      confirm-button-text="接受"
      confirm-button-color="#2387f5"
      cancel-button-text="拒绝"
      @confirm="confirmDisclaimer"
      @cancel="rejectDisclaimer"
    >
      <rich-viewer
        :content="disclaimerContent"
        mobile
        style="padding-top: 12px; height: 60vh"
      />
    </van-dialog>
  </div>
</template>

<script>
  import areaData from '@/utils/area';
  import RichViewer from '@/core/components/RichViewer/index';
  export default {
    name: 'BuyDetailPage',
    components: { RichViewer },
    data() {
      return {
        loading: false,
        disablePay: false,
        goodsQuantity: 1,
        addressSelectorVisible: false,
        addressUpdateVisible: false,
        areaData,
        addressId: '',
        addressInfo: undefined,
        addressData: undefined,
        saveLoading: false,
        deleteLoading: false,
        showDisclaimer: false,
      };
    },
    computed: {
      accountInfo() {
        return this.$currentAccount || {};
      },
      goodsInfo() {
        return this.$currentGoods || {};
      },
      isHeadsetCombo() {
        return this.type === 'headset';
      },
      type() {
        return this.$route.query.type;
      },
      price() {
        return (this.goodsInfo.price || 0) * this.goodsQuantity * 100;
      },
      addressAreaInfo() {
        return (
          (this.addressInfo &&
            `${this.addressInfo.province}-${this.addressInfo.city}-${this.addressInfo.county}`) ||
          ''
        );
      },
      addresseeInfo() {
        return (
          (this.addressInfo &&
            `${this.addressInfo.name} ${this.addressInfo.tel}`) ||
          ''
        );
      },
      addresses() {
        if (this.fetchedData.addresses) {
          return this.fetchedData.addresses.map(address => ({
            ...address,
            address: address.addressDetail,
          }));
        }
        return [];
      },
      submitBarDisabled() {
        if (this.isHeadsetCombo) {
          return !this.addressId || this.disablePay;
        }
        return this.disablePay;
      },
      disclaimerContent() {
        return this.fetchedData.disclaimer || '';
      },
    },
    async created() {
      this.loading = true;
      try {
        await this.$fetchData({
          disclaimer: this.$request('comboBuy/fetchSetting', {
            name: 'disclaimer',
          }),
        });
      } finally {
        this.loading = false;
      }
    },
    activated() {
      this.disablePay = false;
      if (this.isHeadsetCombo) {
        this.refreshAddresses();
      }
    },
    methods: {
      async refreshAddresses() {
        await this.$fetchData({
          addresses: this.$request('comboBuy/fetchAddressList'),
        });
      },
      async saveAddress(data) {
        if (data.id) {
          await this.$request('comboBuy/modifyAddress', {
            ...data,
          });
        } else {
          await this.$request('comboBuy/createAddress', {
            ...data,
          });
        }
      },
      handleAddressSelect(data) {
        this.addressId = data.id;
        this.addressInfo = {
          ...data,
        };
        this.addressSelectorVisible = false;
      },
      handleAddressEdit(data) {
        this.addressData = {
          ...data,
        };
        this.addressUpdateVisible = true;
      },
      handleAddAddress() {
        this.$dialog
          .confirm({
            title: '温馨提示',
            message: '是否从您的微信地址中选择添加？',
          })
          .then(() => {
            // 从微信中获取
            this.$wx.openAddress({
              success: async result => {
                const loadingInstance = this.$toast.loading({
                  message: '保存中...',
                  forbidClick: true,
                  duration: 0,
                });
                try {
                  await this.saveAddress({
                    name: result.userName,
                    tel: result.telNumber,
                    province: result.provinceName,
                    city: result.cityName,
                    county: result.countryName,
                    areaCode: result.nationalCode,
                    postalCode: result.postalCode,
                    addressDetail: result.detailInfo,
                  });
                  await this.refreshAddresses();
                } finally {
                  loadingInstance.clear();
                }
              },
            });
          })
          .catch(() => {
            this.addressUpdateVisible = true;
          });
      },
      confirmDisclaimer() {
        this.showDisclaimer = false;
        this.pay();
      },
      rejectDisclaimer() {
        this.showDisclaimer = false;
      },
      displayDisclaimer() {
        // 提示协议
        this.showDisclaimer = true;
      },
      async pay() {
        // 微信下单
        this.loading = true;
        this.disablePay = true;
        try {
          const { data } = await this.$request(
            'comboBuy/createWxPaymentOrder',
            {
              goodsId: this.goodsInfo.id,
              goodsQuantity: this.goodsQuantity,
              addressId: this.addressId || null,
              accountInfo: JSON.stringify({
                account: this.accountInfo.account,
                name: this.accountInfo.name,
                type: this.accountInfo.type,
                schoolName: this.accountInfo.schoolName,
                gradeName: this.accountInfo.gradeName,
                className: this.accountInfo.className,
              }),
              needDelivery: this.isHeadsetCombo,
            }
          );
          const { timeStamp, ...rest } = data;
          this.$wx.chooseWXPay({
            ...rest,
            timestamp: timeStamp,
            success: () => {
              this.disablePay = true;
              this.$toast.success({
                message: '支付成功',
                duration: 2000,
                onClose: () => {
                  this.$router.push({
                    path: '/my',
                  });
                },
              });
            },
          });
        } finally {
          this.loading = false;
          this.disablePay = false;
        }
      },
      async handleAddressSave(data) {
        this.saveLoading = true;
        try {
          await this.saveAddress(data);
          this.addressUpdateVisible = false;
          await this.refreshAddresses();
        } finally {
          this.saveLoading = false;
        }
      },
      async handleAddressDelete({ id }) {
        if (!id) {
          this.$toast.fail({
            message: '地址ID不存在',
          });
          return;
        }
        this.deleteLoading = true;
        try {
          await this.$request('comboBuy/deleteAddress', {
            id,
          });
          this.addressUpdateVisible = false;
          await this.refreshAddresses();
        } finally {
          this.deleteLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .selector-container {
    height: calc(100% - 58px);
    overflow: hidden;
    padding-top: 8px;
  }
  ::v-deep {
    .van-button--danger {
      color: #fff;
      background-color: #2387f5;
      border: 1px solid #2387f5;
    }

    .van-radio__icon--checked {
      .van-icon-success {
        background-color: #2387f5;
        border-color: #2387f5;
      }
    }
  }
</style>
