import Vue from 'vue';
import VueRouter from 'vue-router';
import path from 'path';
import { trimStart, camelCase, upperFirst } from 'lodash';

Vue.use(VueRouter);

const routes = [];
const context = require.context('../views/', true, /\.vue$/);
context.keys().forEach(key => {
  let requestPath = path.dirname(key).replace(/^\./, '');
  if (requestPath === '') {
    requestPath = '/';
  }
  let name, component;
  if (requestPath !== '/') {
    name = requestPath.substring(requestPath.lastIndexOf('/') + 1);
    component = trimStart(requestPath, '/');
  } else {
    name = path.basename(key, '.vue');
    component = name;
  }
  routes.push({
    path: requestPath,
    name: upperFirst(camelCase(name)),
    component: resolve => require([`@/views/${component}`], resolve),
  });
});

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Tab',
      redirect: 'combo-buy',
      component: () => import('@/tab'),
      children: [
        {
          path: 'combo-buy',
          name: 'ComboBuyTab',
          component: () => import('@/tab/views/combo-buy'),
        },
        {
          path: 'my',
          name: 'MyTab',
          component: () => import('@/tab/views/my'),
        },
      ],
    },
    ...routes,
    {
      path: '*',
      redirect: '/',
    },
  ],
});

export default router;
