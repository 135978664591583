import path from 'path';

// 加载图标
// import '@/icons';
// 加载样式
import './styles/main.scss';
// 预先加载工具函数
import './plugins/_util-function';

// 加载组件
// const publicComponentFilenameReg = new RegExp('^[A-Za-z]\\w*.vue$');
// let context = require.context('./components', true, /\.vue$/);
// context.keys().forEach(filename => {
//   if (publicComponentFilenameReg.test(path.basename(filename))) {
//     // 只加载公共组件
//     const componentConfig = context(filename).default;
//     Vue.component(componentConfig.name, componentConfig);
//   }
// });

// 加载插件
const publicPluginFilenameReg = new RegExp('^[A-Za-z][\\w-]*.js$');
const context = require.context('./plugins', true, /\.js$/);
context.keys().forEach(filename => {
  if (publicPluginFilenameReg.test(path.basename(filename))) {
    // 只加载公共插件
    context(filename);
  }
});
