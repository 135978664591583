<template>
  <div class="page-bg">
    <h1 class="page-title">声明</h1>
    <van-notice-bar wrapable left-icon="info-o" :scrollable="false">
      {{ fetchedData.declaration }}
    </van-notice-bar>
  </div>
</template>

<script>
  export default {
    name: 'DeclarationPage',
    created() {
      this.$fetchData({
        declaration: this.$request('comboBuy/fetchSetting', {
          name: 'declaration',
        }),
      });
    },
  };
</script>
