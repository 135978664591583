import Vue from 'vue';
import { get, post, put, del } from './axios';
import requestContext from '@/api';
import { merge, isPlainObject, isArray } from 'lodash';
import { DELETE, GET, POST, PUT } from '@/constants/api.constants';

const { apiContext } = requestContext;

// 请求方法
const requestMethods = [GET, POST, PUT, DELETE];
const requestMethodMap = {
  [GET]: get,
  [POST]: post,
  [PUT]: put,
  [DELETE]: del,
};
// 匹配uri参数的正则对象
const uriParamReg = new RegExp(`{(\\w+)}`);
/**
 * 替换uri参数
 * @param uri
 * @param params
 * @returns {*}
 */
const replaceUriParams = (uri, params) => {
  while (uriParamReg.test(uri)) {
    const matched = params[RegExp.$1];
    // 删除params中的参数
    delete params[RegExp.$1];
    uri = uri.replace(uriParamReg, matched || '');
  }
  return uri;
};
// 存放所有API
const apiMap = {};

// 处理所有的API请求方法
Object.keys(apiContext).forEach(key => {
  const api = apiContext[key];
  if (
    !api ||
    !isArray(api) ||
    api.length < 2 ||
    !requestMethods.includes(api[0]) ||
    !api[1]
  ) {
    return;
  }
  apiMap[key] = ((method, uri, fixedParams, fixedData, fixedHeaders) => {
    const methodCall = requestMethodMap[method];
    if (method === GET || method === DELETE) {
      // 只有params参数
      return (params = {}, headers = {}) =>
        methodCall(
          replaceUriParams(uri, params),
          merge({}, fixedParams, params),
          merge({}, fixedHeaders, headers)
        );
    }
    return (data = {}, params = {}, headers = {}) =>
      methodCall(
        replaceUriParams(uri, params),
        merge({}, fixedData, data),
        merge({}, fixedParams, params),
        merge({}, fixedHeaders, headers)
      );
  })(
    api[0],
    api[1],
    api.length > 2 ? api[2] || {} : {},
    api.length > 3 ? api[3] || {} : {},
    api.length > 4 ? api[4] || {} : {}
  );
});

// 拓展Vue $api 对象
Vue.prototype.$api = {
  ...(isPlainObject(Vue.prototype.$api) ? Vue.prototype.$api : {}),
  ...apiMap,
};

// 拓展一个实用的工具方法
export const request = (apiNamespace, ...args) => {
  return Vue.prototype.$api[apiNamespace]
    ? Vue.prototype.$api[apiNamespace](...args)
    : Promise.reject(`找不到命名空间 ${apiNamespace} 的API`);
};
Vue.prototype.$request = request;
