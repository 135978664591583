import Vue from 'vue';
import Vuex from 'vuex';
import { camelCase } from 'lodash';

Vue.use(Vuex);

const context = require.context('./modules', false, /\.js$/);
const modules = {};

context.keys().forEach(key => {
  modules[camelCase(key.replace(/(\.\/|\.js)/g, ''))] = {
    namespaced: true,
    ...context(key).default,
  };
});

const store = new Vuex.Store({
  modules,
});
export default store;
