<template>
  <loading height="100vh" tip="登录中，请稍后..." />
</template>

<script>
  import { trimStart } from 'lodash';
  import qs from 'qs';

  export default {
    name: 'LoginPage',
    async created() {
      if (this.$route.query.code) {
        const { code, state } = this.$route.query;
        await this.fetchAccessToken(code);
        if (state) {
          this.$router.push(state);
        }
        return;
      }
      const query = trimStart(window.location.search, '?');
      if (!query) {
        return;
      }
      const params = qs.parse(query);
      if (params && params.code) {
        const { code, state } = params;
        await this.fetchAccessToken(code);
        window.location = `/#${state ? state : '/'}`;
      }
    },
    methods: {
      async fetchAccessToken(code) {
        const { data } = await this.$request('auth/fetchAccessToken', {
          code,
        });
        await this.$store.dispatch('auth/saveToken', {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        });
      },
    },
  };
</script>
