import Vue from 'vue';
import router from '@/router';

let loadingInstance;
router.beforeEach((to, from, next) => {
  loadingInstance = Vue.prototype.$toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 0,
  });
  next();
});
router.afterEach(() => {
  loadingInstance.clear();
});
