import { DELETE, GET, POST, PUT } from '@/constants/api.constants';

/**
 * API 请求配置
 * @type {{}}
 */
const api = {
  /**
   * 获取商品信息
   */
  fetchGoods: [GET, '/wx/mp/comboBuy/goods'],
  /**
   * 获取账号信息
   */
  fetchAccountInfo: [GET, '/wx/mp/comboBuy/accountInfo'],
  /**
   * 创建微信订单
   */
  createWxPaymentOrder: [POST, '/wx/mp/comboBuy/wxPaymentOrder'],
  /**
   * 获取地址
   */
  fetchAddressList: [GET, '/wx/mp/comboBuy/address'],
  /**
   * 添加地址
   */
  createAddress: [POST, '/wx/mp/comboBuy/address'],
  /**
   * 修改地址
   */
  modifyAddress: [PUT, '/wx/mp/comboBuy/address'],
  /**
   * 删除地址
   */
  deleteAddress: [DELETE, '/wx/mp/comboBuy/address'],
  /**
   * 获取订单列表
   */
  fetchOrders: [GET, '/wx/mp/comboBuy/order'],
  /**
   * 获取设置信息
   */
  fetchSetting: [GET, '/system/settings/setting'],
};

/**
 * API 权限配置
 * @type {{}}
 */
const perm = {};

export default {
  api,
  perm,
};
