import { setState } from '@/utils/store';
import { isPlainObject, omit, zipObject } from 'lodash';

/**
 * 当前路由路径命名空间
 * @type {string}
 */
const routePathNamespace = 'route/currentRoutePath';

export default {
  state: {
    fetchedData: {},
    currentGoods: null,
    currentAccount: null,
  },
  getters: {
    fetchedData: (state, getters, rootState, rootGetters) =>
      state.fetchedData[rootGetters[routePathNamespace]],
    currentGoods: state => state.currentGoods,
    currentAccount: state => state.currentAccount,
  },
  mutations: {
    setState,
  },
  actions: {
    /**
     * 设置获取的数据
     * @param dispatch
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param dataPayload
     */
    setFetchedData({ commit, state, getters, rootGetters }, dataPayload) {
      const oldValue = getters.fetchedData;
      commit('setState', {
        path: 'fetchedData',
        value: {
          ...state.fetchedData,
          [rootGetters[routePathNamespace]]: {
            ...(isPlainObject(oldValue) ? oldValue : {}),
            ...dataPayload,
          },
        },
      });
    },
    /**
     * 清除当前路由的data数据
     * @param commit
     * @param state
     * @param rootGetters
     */
    clearFetchedData({ commit, state, rootGetters }) {
      commit('setState', {
        path: 'fetchedData',
        value: omit(state.fetchedData, rootGetters[routePathNamespace]),
      });
    },
    /**
     * 异步获取数据
     * @param dispatch
     * @param promisePayload
     * @param ordered
     * @returns {Promise<{}>}
     */
    async fetchData(
      { dispatch },
      { promises: promisePayload, ordered = false }
    ) {
      if (!isPlainObject(promisePayload)) {
        console.warn('fetchData Action 必须传入key-Promise的对象！');
        throw Error('fetchData Action 必须传入key-Promise的对象！');
      }
      let data = {};
      const promiseKeys = Object.keys(promisePayload);
      if (!promiseKeys.length) {
        return data;
      }
      // 处理promise结果
      // 如果不需要顺序执行，则需要用到
      const keys = [],
        promises = [];
      for (let i = 0, length = promiseKeys.length; i < length; i++) {
        const key = promiseKeys[i];
        if (!(promisePayload[key] instanceof Promise)) {
          console.warn('fetchData Action key-Promise 值必须是Promise对象！');
          continue;
        }
        // 判断是否有序
        if (ordered) {
          try {
            const resp = await promisePayload[key];
            data[key] =
              // eslint-disable-next-line no-prototype-builtins
              resp.hasOwnProperty('data') && resp.data ? resp.data : resp;
            // eslint-disable-next-line no-empty
          } catch {} // 忽略错误
          continue;
        }
        keys.push(key);
        promises.push(promisePayload[key]);
      }
      if (!ordered) {
        // 集中处理promise
        try {
          const responses = await Promise.all(promises);
          data = zipObject(
            keys,
            responses.map(resp =>
              // eslint-disable-next-line no-prototype-builtins
              resp.hasOwnProperty('data') && resp.data ? resp.data : resp
            )
          );
          // eslint-disable-next-line no-empty
        } catch {} // 忽略错误
      }
      await dispatch('setFetchedData', data);
      return data;
    },
  },
};
