import store from '@/store';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { durableKeys } from '@/config/settings';

const getters = Object.keys(store.getters).filter(getter =>
  getter.startsWith('data')
);

durableKeys.forEach(key => {
  const value = window.localStorage.getItem(key);
  if (value) {
    store.commit('data/setState', {
      path: key,
      value: JSON.parse(value),
    });
  }
});

Vue.mixin({
  computed: {
    /**
     * 映射所有的data store中的getters
     */
    ...mapGetters(
      getters.reduce((o, getter) => {
        o[`$${getter.replace(/^data\//, '')}`] = getter;
        return o;
      }, {})
    ),
    fetchedData() {
      return this.$fetchedData || {};
    },
  },
  methods: {
    /**
     * 获取数据，不关心顺序
     * @param promises
     * @returns {Promise<any>}
     */
    $fetchData(promises) {
      return store.dispatch('data/fetchData', {
        promises,
        ordered: false,
      });
    },
    /**
     * 获取数据，关心顺序
     * @param promises
     * @returns {Promise<any>}
     */
    $fetchDataOrdered(promises) {
      return store.dispatch('data/fetchData', {
        promises,
        ordered: true,
      });
    },
    /**
     * 设置数据
     * @param path
     * @param value
     */
    $setData(path, value) {
      store.commit('data/setState', {
        path,
        value,
      });
      if (durableKeys.includes(path)) {
        window.localStorage.setItem(path, JSON.stringify(value));
      }
    },
  },
});
