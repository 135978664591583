import Vue from 'vue';
import { request } from '@/core/plugins/api';
import { domain } from '@/config/settings';

const init = async () => {
  const { data } = await request('auth/fetchJsApiSignature', {
    url: `${domain}/`,
  });
  window.wx.config({
    debug: false,
    ...data,
    jsApiList: ['openAddress', 'chooseWXPay'],
  });
};
init();
Vue.prototype.$wx = window.wx;
