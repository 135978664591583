import Vue from 'vue';
import {
  isFunction,
  isPlainObject,
  isArray,
  isString,
  isEqual,
  trim,
  trimStart,
  trimEnd,
  zipObject,
  omit,
  camelCase,
  kebabCase,
  cloneDeep,
} from 'lodash';

/**
 * 异步函数类型
 */
const AsyncFunction = (async () => {}).constructor;
/**
 * 自定义函数，判断一个函数是否是异步的
 * @param func
 * @returns {boolean}
 */
const isAsyncFunction = func => {
  return func instanceof AsyncFunction;
};

/* 全局lodash工具方法 */
// 由于lodash中的方法众多，而我们日常用到的却就那么几个，多以单独列出来
Vue.prototype.$isAsyncFunction = isAsyncFunction;
Vue.prototype.$isFunction = isFunction;
Vue.prototype.$isPlainObject = isPlainObject;
Vue.prototype.$isArray = isArray;
Vue.prototype.$isString = isString;
Vue.prototype.$trim = trim;
Vue.prototype.$trimStart = trimStart;
Vue.prototype.$trimEnd = trimEnd;
Vue.prototype.$isEqual = isEqual;
Vue.prototype.$zipObject = zipObject;
Vue.prototype.$omit = omit;
Vue.prototype.$camelCase = camelCase;
Vue.prototype.$kebabCase = kebabCase;
Vue.prototype.$cloneDeep = cloneDeep;

/* 全局事件总线 */
Vue.prototype.$eventBus = new Vue();
