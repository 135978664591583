import Vue from 'vue';
import Vant from 'vant';
import { Lazyload } from 'vant';

import 'vant/lib/index.css';

Vue.use(Vant);
Vue.use(Lazyload, {
  lazyComponent: true,
});
