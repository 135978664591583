import Vue from 'vue';

Vue.mixin({
  methods: {
    async $requiredLogin() {
      if (!this.$store.getters['auth/hasAccessToken']) {
        // 跳转登录
        await this.$store.dispatch('auth/login', this.$route.fullPath);
      }
    },
  },
});
