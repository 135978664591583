<template>
  <div class="loading" :style="{ height }">
    <van-loading :size="size" type="circle" vertical>{{ tip }}</van-loading>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    props: {
      tip: {
        type: String,
        default: '加载中...',
      },
      size: {
        type: String,
        default: '60px',
      },
      height: {
        type: String,
        default: '100%',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
