<template>
  <div class="introduction">
    <img
      v-for="image in images[type]"
      v-lazy="require(`@/assets/${type}/${image}`)"
      :key="image"
      alt="app介绍"
    />
  </div>
</template>

<script>
  import helpImages from '@/assets';
  export default {
    name: 'HelpPage',
    computed: {
      type() {
        return this.$route.query.type || 'student';
      },
      images() {
        return {
          student: this.studentImages,
          teacher: this.teacherImages,
          parents: this.parentsImages
        };
      },
      studentImages() {
        return helpImages.student.sort((a, b) => parseInt(a.substring(0, a.lastIndexOf('.'))) - parseInt(b.substring(0, b.lastIndexOf('.'))));
      },
      teacherImages() {
        return helpImages.teacher.sort((a, b) => parseInt(a.substring(0, a.lastIndexOf('.'))) - parseInt(b.substring(0, b.lastIndexOf('.'))));
      },
      parentsImages() {
        return helpImages.parents.sort((a, b) => parseInt(a.substring(0, a.lastIndexOf('.'))) - parseInt(b.substring(0, b.lastIndexOf('.'))));
      }
    },
  };
</script>

<style lang="scss" scoped>
  .introduction {
    overflow: auto;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
</style>
