const isDev = 'development' === process.env.NODE_ENV;
/**
 * @description 全局变量配置
 */
module.exports = {
  baseUrl: 'http://localhost:8787',
  // 开发环境端口号
  devPort: '10000',
  // 配后端数据的接收方式application/json;charset=UTF-8或者application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 30000,
  // 操作正常code
  successCodes: [0, '200'],
  // 持久化数据
  durableKeys: ['currentGoods', 'currentAccount'],
  // 部署后的域名
  domain: isDev
    ? 'https://znb.natapp4.cc'
    : 'https://www.szztjy.cn',
  // 公有路径
  publicPath: process.env.NODE_ENV === 'development' ? '' : '/mp',
};
