import { GET, POST } from '@/constants/api.constants';
import { domain } from '@/config/settings';

/**
 * API 请求配置
 * @type {{}}
 */
const api = {
  /**
   * 根据账号登录
   */
  fetchOauthUrl: [
    GET,
    '/wx/mp/auth/oauthUrl',
    {
      redirectUrl: `${domain}/#/login`,
    },
  ],
  /**
   * 获取AccessToken
   */
  fetchAccessToken: [GET, '/wx/mp/auth/accessToken'],
  /**
   * 刷新AccessToken
   */
  refreshAccessToken: [POST, '/wx/mp/auth/refreshAccessToken'],
  /**
   * 获取JS API签名信息
   */
  fetchJsApiSignature: [GET, '/wx/mp/auth/jsApiSignature'],
};

/**
 * API 权限配置
 * @type {{}}
 */
const perm = {};

export default {
  api,
  perm,
};
