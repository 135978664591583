import { trimStart } from 'lodash';

const student = require.context('./student', true).keys();
const parents = require.context('./parents', true).keys();
const teacher = require.context('./teacher', true).keys();

export default {
  student: student.map(key => trimStart(key, './')),
  parents: parents.map(key => trimStart(key, './')),
  teacher: teacher.map(key => trimStart(key, './')),
};
