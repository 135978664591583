import { has, set } from 'lodash';

/**
 * 通用状态设置
 * @param state
 * @param path
 * @param value
 */
export const setState = (state, { path, value }) => {
  if (!has(state, path)) {
    return;
  }
  set(state, path, value);
};
