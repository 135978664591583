import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import { contentType, requestTimeout, successCodes } from '@/config/settings';
import { isPlainObject } from 'lodash';
import store from '@/store';
import { Toast } from 'vant';
import { request } from '@/core/plugins/api';
import router from '@/router';

/**
 * 阻塞状态
 * @type {boolean}
 */
let block = false;
/**
 * 请求队列
 * @type {*[]}
 */
const requestQueue = [];

/**
 * API响应错误状态处理
 * @param code
 * @param message
 */
const routeFailureAction = async (code, message) => {
  if (code === 9) {
    // 刷新Token失败，直接跳过
    return;
  }
  if (code === 10) {
    // token失效，使用refreshToken刷新
    try {
      const { data } = await new Promise((resolve, reject) => {
        request('auth/refreshAccessToken', {
          refreshToken: store.getters['auth/refreshToken'],
        })
          .then(resolve)
          .catch(reject);
        // 阻塞请求
        block = true;
      });
      await store.dispatch('auth/saveToken', {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      });
      // 重新发送所有请求
      requestQueue.forEach(request => request());
      block = false;
      return true;
    } catch {
      // 刷新Token出现异常，则重新登录
      // 跳转登录
      block = false;
      await store.dispatch('auth/login', router.currentRoute.fullPath);
    }
    return;
  }
  Toast(message);
};

/**
 * @description axios初始化
 */
const instance = axios.create({
  baseURL: '',
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
  transformRequest: [
    /**
     * 转换请求参数
     * @param data
     * @param headers
     * @returns {string}
     */
    (data, headers) => {
      if (!data) {
        return '';
      }
      if (
        headers['Content-Type'] &&
        headers['Content-Type']
          .toLowerCase()
          .startsWith('application/x-www-form-urlencoded')
      ) {
        return qs.stringify(data);
      }
      return JSON.stringify(data);
    },
  ],
  /**
   * 序列化URL参数
   * @param params
   * @returns {string}
   */
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
  responseType: 'json',
  /**
   * 所有状态都resolve
   * @returns {boolean}
   */
  validateStatus: () => true,
});

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  config => {
    const accessToken = store.getters['auth/accessToken'];
    if (accessToken) {
      config.headers['X-Mp-Access-Token'] = accessToken;
    }
    return config;
  },
  error => {
    const { message } = error;
    Toast(message);
    return Promise.reject(message);
  }
);

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  async response => {
    const { data } = response;
    const { code, message } = data;
    // 正常直接返回
    if (successCodes.includes(code)) {
      return data;
    } else {
      // 展示错误信息
      const resend = await routeFailureAction(code, message);
      if (resend === true) {
        // 重新发送请求
        return Promise.reject(resend);
      }
      return Promise.reject(data);
    }
  },
  error => {
    const { response, message } = error;
    if (response && response.data) {
      // 此处为处理响应信息时的逻辑错误
      Toast(message);
      return Promise.reject(message);
    } else {
      // 处理提示信息
      let info = message;
      if (message === 'Network Error') {
        info = '接口连接异常';
      }
      if (message.includes('timeout')) {
        info = '接口请求超时';
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3);
        info = '接口状态码异常：' + code;
      }
      Toast(info);
      return Promise.reject(info);
    }
  }
);

// 简化请求方法
export const get = (uri, params = {}, headers = {}) => {
  if (!block) {
    return new Promise((resolve, reject) => {
      instance
        .get(uri, {
          headers,
          params,
        })
        .then(resolve)
        .catch(resend => {
          if (resend === true) {
            instance
              .get(uri, {
                headers,
                params,
              })
              .then(resolve)
              .catch(reject);
            return;
          }
          reject(resend);
        });
    });
  }
  return new Promise((resolve, reject) => {
    requestQueue.push(() => {
      instance
        .get(uri, {
          headers,
          params,
        })
        .then(resolve)
        .catch(reject);
    });
  });
};

export const post = (uri, data = {}, params = {}, headers = {}) => {
  if (!block) {
    return new Promise((resolve, reject) => {
      instance
        .post(uri, data, {
          headers,
          params,
        })
        .then(resolve)
        .catch(resend => {
          if (resend === true) {
            instance
              .post(uri, data, {
                headers,
                params,
              })
              .then(resolve)
              .catch(reject);
            return;
          }
          reject(resend);
        });
    });
  }
  return new Promise((resolve, reject) => {
    requestQueue.push(() => {
      instance
        .post(uri, data, {
          headers,
          params,
        })
        .then(resolve)
        .catch(reject);
    });
  });
};

export const put = (uri, data = {}, params = {}, headers = {}) => {
  if (!block) {
    return new Promise((resolve, reject) => {
      instance
        .put(uri, data, {
          headers,
          params,
        })
        .then(resolve)
        .catch(resend => {
          if (resend === true) {
            instance
              .put(uri, data, {
                headers,
                params,
              })
              .then(resolve)
              .catch(reject);
            return;
          }
          reject(resend);
        });
    });
  }
  return new Promise((resolve, reject) => {
    requestQueue.push(() => {
      instance
        .put(uri, data, {
          headers,
          params,
        })
        .then(resolve)
        .catch(reject);
    });
  });
};

export const del = (uri, params = {}, headers = {}) => {
  if (!block) {
    return new Promise((resolve, reject) => {
      instance
        .delete(uri, {
          headers,
          params,
        })
        .then(resolve)
        .catch(resend => {
          if (resend === true) {
            instance
              .delete(uri, {
                headers,
                params,
              })
              .then(resolve)
              .catch(reject);
            return;
          }
          reject(resend);
        });
    });
  }
  return new Promise((resolve, reject) => {
    requestQueue.push(() => {
      instance
        .delete(uri, {
          headers,
          params,
        })
        .then(resolve)
        .catch(reject);
    });
  });
};

// 挂载请求方法
Vue.prototype.$api = {
  ...(isPlainObject(Vue.prototype.$api) ? Vue.prototype.$api : {}),
  get,
  post,
  put,
  delete: del,
};
