<template>
  <div class="container">
    <iframe
      ref="iframe"
      src="about:blank"
      sandbox="allow-scripts allow-same-origin"
      data-alloy-tabstop="true"
      tabindex="-1"
      class="content"
    ></iframe>
  </div>
</template>

<script>
  import { buildContentDoc } from '@/utils/rich-text';

  export default {
    name: 'RichViewer',
    props: {
      content: {
        type: String,
        required: true,
      },
      mobile: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      doc() {
        return buildContentDoc(this.content, this.mobile);
      },
    },
    mounted() {
      this.$refs.iframe.contentWindow.document.write(this.doc);
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    .content {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
</style>
