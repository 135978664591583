import router from '@/router';
import store from '@/store';

/**
 * 设置当前路由的路径
 */
router.beforeResolve((to, from, next) => {
  // 清除之前的数据
  // store.dispatch('data/clearFetchedData');
  // 更新当前路由
  store.commit('route/setState', {
    path: 'currentRoutePath',
    value: to.path,
  });
  next();
});
