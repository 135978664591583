import { camelCase, isPlainObject } from 'lodash';

const context = require.context('.', true, /\.api\.js$/);
const apiContext = {};
const permissionContext = {};

/**
 * 处理API和权限项的命名空间
 * @param context
 * @param prefix
 * @param object
 */
const handleNamespace = (context, prefix, object) => {
  Object.keys(object).forEach(key => {
    context[
      [prefix, key]
        .join('/')
        .split('/')
        .map(token => camelCase(token))
        .join('/')
    ] = object[key];
  });
};

// 动态加载所有的API
context.keys().forEach(key => {
  const api = context(key).default;
  const apiKey = key.replace(/(^\.\/)|(\.api\.js$)/g, '');
  if (isPlainObject(api.api)) {
    handleNamespace(apiContext, apiKey, api.api);
  }
  if (isPlainObject(api.perm)) {
    handleNamespace(permissionContext, apiKey, api.perm);
  }
});

export default {
  apiContext,
  permissionContext,
};
