<template>
  <div class="introduction">
    <img
      v-for="image in images[type]"
      v-lazy="require(`@/assets/${image}`)"
      :key="image"
      alt="app介绍"
    />
  </div>
</template>

<script>
  export default {
    name: 'IntroductionPage',
    data() {
      return {
        images: {
          training: ['app1.jpeg', 'app2.jpeg'],
          simulation: [
            'training/00.jpg',
            'training/01.jpg',
          ],
        },
      };
    },
    computed: {
      type() {
        return this.$route.query.type || 'training';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .introduction {
    overflow: auto;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
</style>
