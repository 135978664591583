<template>
  <div class="container">
    <transition :name="transitionName">
      <keep-alive>
        <router-view class="router-view page-bg" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        transitionName: 'slide-left',
      };
    },
    beforeRouteUpdate(to, from, next) {
      if (this.$router.isBack) {
        this.transitionName = 'slide-right';
      } else {
        this.transitionName = 'slide-left';
      }
      this.$router.isBack = false;
      next();
    },
  };
</script>

<style lang="scss" scoped>
  $translate-width: 20%;
  .container {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  .router-view {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
  }
  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    -webkit-transform: translate($translate-width, 0);
    transform: translate($translate-width, 0);
  }
  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    -webkit-transform: translate(-$translate-width, 0);
    transform: translate(-$translate-width, 0);
  }
</style>
