/**
 * @description 路由
 */
import router from '@/router';
import { setState } from '@/utils/store';

const state = {
  currentRoutePath: router.currentRoute.path,
};
const getters = {
  currentRoutePath: state => state.currentRoutePath,
};
const mutations = {
  setState,
};
const actions = {};
export default { state, getters, mutations, actions };
