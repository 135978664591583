<template>
  <i :class="fontIconName" v-on="$listeners" />
</template>

<script>
  import 'remixicon/fonts/remixicon.css';

  export default {
    name: 'Icon',
    props: {
      iconName: {
        type: String,
        default: 'error-warning-line',
      },
      iconClass: {
        type: String,
        default: '',
      },
    },
    computed: {
      fontIconName() {
        return `ri-${this.iconName}${
          this.iconClass ? ' ' + this.iconClass : ''
        }`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  [class*='ri'] {
    font-size: 16px;
    vertical-align: -3.5px;
  }

  .svg-icon {
    width: 1em;
    height: 1em;
    overflow: hidden;
    vertical-align: middle;
    fill: currentColor;
  }

  .img-icon {
    display: inline-block;
    width: 2em;
    height: 2em;
    vertical-align: middle;
  }
</style>
